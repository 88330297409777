<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 pb-16 lg:pb-0">
    <ProjectSkeleton
      v-for="index in count"
      :key="'project-skeleton-' + index"
    />
  </div>
</template>
<script>
import ProjectSkeleton from '@/components/projects/Skeleton.vue'

export default {
  components: { ProjectSkeleton },
  props:{
    count: {
      type: Number,
      default: 4
    }
  },
  setup() {}
}
</script>
